<template>
  <b-row>
    <b-col md="12">
      <b-card no-body>
        <b-card-header>
          <b-card-title>Tüm Talepler</b-card-title>
        </b-card-header>
        <b-table
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
        >
          <template #cell(company)="data">
            <div class="font-weight-bold">
              {{ data.item.company }}
            </div>
            <div class="text-primary">
              <strong>Talep Eden: </strong> {{ data.item.customer_user }}
            </div>
          </template>
          <template #cell(status)="data">
            <div class="text-center">
              <FeatherIcon
                v-if="data.item.status === '1'"
                class="text-success"
                icon="CheckIcon"
                size="24"
              />
              <FeatherIcon
                v-else
                class="text-info"
                icon="ClockIcon"
                size="24"
              />
            </div>
          </template>
          <template #cell(control)="data">
            <b-button
              variant="outline-primary"
              size="sm"
              :to="'/service_requests/view/' + data.item.id"
            >
              Görüntüle
            </b-button>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BTable,
  BCardFooter,
  BPagination,
  BButton,
  VBPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'Services',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'id',
          label: 'NO',
          thStyle: { width: '60px', textAlign: 'left', verticalAlign: 'middle' },
        },
        {
          key: 'company',
          label: 'FİRMA ADI',
        },
        {
          key: 'status',
          label: 'DURUM',
          thStyle: { width: '120px', textAlign: 'center', verticalAlign: 'middle' },
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '160px', textAlign: 'center', verticalAlign: 'middle' },
        },
      ],
      dataQuery: {
        select: [
          'service_requests.id AS id',
          'service_requests.status AS status',
          'customers.company AS company',
          'customer_users.name AS customer_user',
        ],
        where: {
          'service_requests.status': 1,
        },
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['serviceRequests/getService_requests']
    },
    dataCount() {
      return this.$store.getters['serviceRequests/getService_requestsCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('serviceRequests/service_requestsList', this.dataQuery)
    },
  },
}
</script>

<style scoped></style>
